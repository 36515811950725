<template>
  <div>
    <!-- <v-btn v-show="!hidden" color="red darken-3" dark fixed bottom right fab>
      <v-icon>mdi-heart</v-icon>
    </v-btn> -->
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" color="red darken-3" dark fixed bottom right fab large>
          <v-icon>mdi-comment-text-multiple</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title> Feedback </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="description" label="Your feedback">
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="red darken-3" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="green darken-3" text @click="sendFeedbacks()">
            Send
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-footer padless>
      <v-row
        justify="center"
        no-gutters
        class="blue-grey lighten-4 text-center py-1"
      >
        <v-btn
          v-for="link in links"
          :key="link.url"
          :to="link.url"
          :href="link.href"
          :target="link.target"
          x-small
          text
        >
          {{ link.text }}
        </v-btn>
        <v-col class="blue-grey lighten-4 py-1 text-center" cols="12">
          {{ new Date().getFullYear() }} — <strong>Expertime</strong>
        </v-col>
      </v-row>
    </v-footer>
    <v-snackbar v-model="snackbar" :timeout="2000" right :color="confirmColor">
      {{ confirmText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import FeedbacksService from "@/services/FeedbacksService.js";

export default {
  name: "Footer",
  components: {},
  data: function () {
    return {
      links: null,
      dialog: false,
      description: "",
      snackbar: false,
      confirmText: "",
      confirmColor: "",
    };
  },
  computed: {},
  methods: {
    async sendFeedbacks() {
      this.dialog = false;
      const feedbacksService = new FeedbacksService(
        this.$route.params.accountName
      );
      const response = await feedbacksService.sendFeedbacks(
        this.description,
        this.$router.currentRoute.path
      );
      if (response.ok) {
        this.description = "";
        this.confirmColor = "green";
        this.confirmText = "Success ! 🥳";
      } else {
        this.confirmText = "An error occured, please try again later";
        this.confirmColor = "red";
      }
      this.snackbar = true;
    },
  },
  created() {},
  mounted() {
    const accountName = this.$route.params.accountName;
    this.links = [
      {
        text: "Conditions of Use",
        url: `/${accountName}/cgu`,
      },
      {
        text: "Privacy policy",
        url: `/${accountName}/privacy`,
      },
      {
        text: "Contact",
        href: "https://expertime.com/contactez-nous/",
        target: "_blank",
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding-left: 300px;
}
</style>