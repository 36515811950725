<template>
  <v-navigation-drawer class="mx-auto main-nav" app permanent width="300">
    <div id="logo-wrapper">
      <router-link :to="homeUrl">
        <img id="logo" src="../assets/logo.png" />
      </router-link>
    </div>
    <v-list>
      <v-divider></v-divider>
      <v-list-item-group v-model="selectedItem">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.url"
          :href="item.href"
          :target="item.target"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <div :class="isSelected(item) ? 'left-stuff' : ''"></div>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <v-list class="bottom-links">
        <v-divider></v-divider>
        <v-list-item-group v-model="selectedItem2">
          <v-list-item
            v-for="item in logoutItems"
            :key="item.title"
            link
            @click="logout()"
            class="v-item--active v-list-item--active"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import useAuthStore from "../stores/authStore";

export default defineComponent({
  data: () => ({
    selectedItem: 0,
    selectedItem2: 0,
    items: null,
    logoutItems: null,
    homeUrl: "",
    me: null,
  }),
  setup() {
    const authStore = useAuthStore();

    return {
      authStore: authStore,
    };
  },
  async mounted() {
    const accountName = this.$route.params.accountName;

    if (
      !accountName ||
      accountName == "undefined" ||
      accountName === undefined
    ) {
      return;
    }

    await this.authStore.setMe(accountName);
    this.me = await this.authStore.getMe;

    if (!this.me.agreed) {
      this.$router.replace("cgu");
    }
    if (this.$router.history.current.meta?.admin && !this.me?.isAdmin) {
      this.$router.replace("audits");
    }

      this.homeUrl = `/${accountName}/audits`;
      this.items = [
        {
          title: "Audits",
          icon: "mdi-magnify-scan",
          url: `/${accountName}/audits`,
          target: "_self",
        },
        {
          title: "Docs",
          icon: "mdi-file-document-outline",
          href: "https://expertime.github.io/greenops/",
          target: "_blank",
        },
        {
          title: "Settings",
          icon: "mdi-cog-outline",
          url: `/${accountName}/settings`,
          target: "_self",
        },
      ];

      if (this?.me?.isAdmin) {
        this.items.push({
          title: "Admin",
          icon: "mdi-account-multiple-outline",
          url: `/${accountName}/admin`,
          target: "_self",
        });
      }

      this.items.push({
          title: "Help",
          icon: "mdi-help",
          url: `/${accountName}/help`,
          target: "_self",
        });

      this.logoutItems = [
        {
          title: "Logout",
          icon: "mdi-logout",
          url: `/logout`,
        },
      ];
      this.selectedItem2 = this.logoutItems[0];
  },
  methods: {
    isSelected(item) {
      return this.$router.currentRoute.path.includes(item.url);
    },
    logout() {
      this.authStore.logout();
    },
  },
});
</script>

<style lang="scss">
.v-navigation-drawer.main-nav {
  // -webkit-box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.12);
  // box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.12);
  // background-image: url("../assets/bg.png");
  // background-position-x: -350px;
  background-color: #020240 !important;
}

.v-navigation-drawer.main-nav .v-list,
.v-navigation-drawer.main-nav .v-list .v-list-item,
.v-navigation-drawer.main-nav
  .theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled),
.v-navigation-drawer.main-nav .v-icon.v-icon {
  color: white !important;
}

.v-navigation-drawer.main-nav .v-icon.v-icon {
  font-weight: 100;
}

.v-navigation-drawer.main-nav .v-item-group a {
  padding-left: 50px;
}

div#logo-wrapper {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

img#logo {
  max-width: 75%;
  max-height: 75%;
  margin: auto;
}

.left-stuff {
  position: absolute;
  width: 8px;
  height: 100%;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.24;
  content: "";
}
</style>