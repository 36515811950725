import useAuthStore from '../stores/authStore'
import Router from '../router';

export default class BaseService {
    constructor(accountName) {
        this.authStore = useAuthStore();
        this.accountName = accountName;

        this.baseUrl = `${process.env.VUE_APP_BASE_API_URL}/api`;
        if (accountName) {
            this.baseUrl += `/${this.accountName}`;
        }
    }

    async sendRequest(url, method, body) {
        let accessToken = "";
        try {
            accessToken = await this.authStore.getTokenRedirect();
        } catch (error) {
            console.log(error);
            Router.push("/")
        }

        if (!method) {
            method = 'GET';
        }

        const options = {
            method: method,
            headers: { 'Authorization': `Bearer ${accessToken}`, 'Content-Type': 'application/json' },
        }

        if (method !== 'GET') {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(
            url, options
        );

        return response;
    }
}