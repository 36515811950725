import BaseService from "./BaseService"

export default class AccountService extends BaseService {
    async getAccounts() {
        return await this.sendRequest(`${this.baseUrl}/accounts`);
    }

    async agreeConditions() {
        return await this.sendRequest(`${this.baseUrl}/agree`, "POST", {});
    }
}